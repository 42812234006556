import firebase from 'firebase/app';
import 'firebase/firestore';

const config = {
  apiKey: "AIzaSyBI2lU-6EhbROb6kDTxeHzoUmQ_xkg-mKs",
  authDomain: "flashcards-24c84.firebaseapp.com",
  databaseURL: "https://flashcards-24c84.firebaseio.com",
  projectId: "flashcards-24c84",
  storageBucket: "flashcards-24c84.appspot.com",
  messagingSenderId: "143234929433"
};

firebase.initializeApp(config);
const db = firebase.firestore();

export {db}
export default firebase;